import React, { useEffect, useState, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import VoucherPreview from './VoucherPreview/VoucherPreview'
import kenticoClient from '../../utils/kenticoClient'
import VoucherModel from '../../models/voucher/VoucherModel'
import PartnerInfoModel from '../../models/voucher/PartnerInfoModel'
import projects from '../../resources/projects'
import partnerInfoCodeNamesConfig from '../../resources/partnerInfoCodeNames'
import projectInfoConfiguration from '../../resources/partnerInfoConfiguration'
import { VoucherContext } from './VoucherContext'

interface VoucherProviderProps
  extends RouteComponentProps<{ projectId: string; voucherSlug: string }> {}

const VoucherProvider: React.FC<VoucherProviderProps> = (props) => {
  const { projectId, voucherSlug } = props.match.params
  const [voucher, setVoucher] = useState<VoucherModel>()
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfoModel>()

  const partnerInfoCodeName = useMemo(()=> {
        return Object.values(partnerInfoCodeNamesConfig).find(
            (project) => project.projectId === projectId
        )
  } ,[projectId])

  const projectConfig = Object.values(projects).find(
    (project) => project.projectId === projectId
  )

  useEffect(() => {
    kenticoClient(projectConfig)
      .items<VoucherModel>()
      .type('voucher')
      .equalsFilter('elements.slug', voucherSlug)
      .toObservable()
      .subscribe((response) => {
        setVoucher(response.items[0])
      })
  }, [projectConfig, voucherSlug])

  useEffect(() => {
    kenticoClient(projectInfoConfiguration)
        .item<PartnerInfoModel>(`${partnerInfoCodeName?.codeName}`)
        .toObservable()
        .subscribe((response) => {
          if(response){
            setPartnerInfo(response.item)
          }
        })
  }, [partnerInfoCodeName])

  return (
    <VoucherContext.Provider value={{ voucher, partnerInfo }}>
        <VoucherPreview />
    </VoucherContext.Provider>
  )
}

export default VoucherProvider
