import React, { useContext, useRef, useState } from 'react';
import MobileDetailsHeader from './MobileDetailsHeader/MobileDetailsHeader';
import VoucherItem from '../VoucherItem/VoucherItem';
import './MobileDetails.css';
import { VoucherContext } from '../VoucherContext';
import getVoucherTypeConfig from '../../../utils/getVoucherTypeConfig';
import Constants from '../../../resources/constants/constants';
import { ReactComponent as InfoIconBlue } from '../../../svgs/icn-big-info-blue.svg';
import { ReactComponent as InfoIconGreen } from '../../../svgs/icn-big-info-green.svg';
import { ReactComponent as InfoIconPurple } from '../../../svgs/icn-big-info-purple.svg';
import { ReactComponent as StatusBarSvg } from '../../../svgs/status-bar.svg';
import { ReactComponent as ImpressumIcon } from '../../../svgs/icn-impressum.svg';
import { ReactComponent as SmallArrowUpIcon } from '../../../svgs/icn-arrow-small-up.svg';
import { ReactComponent as WebshopIcon } from '../../../svgs/icn_cart_gold.svg';
import { ReactComponent as IconBarcode } from '../../../svgs/icn-barcode-big.svg';
import NoImgPlaceholder from '../../../images/no-img-placeholder-2.png';
import CollectingRule from './CollectingRule/CollectingRule';
import MobileDetailsFooter from './MobileDetailsFooter/MobileDetailsFooter';
import useDetectBottomScroll from '../../../utils/useDetectBottomScroll';
import oesImage from '../../../resources/images/navigation-elements-oes-top@3x.jpg';
import getPartnerLogoAndName from '../../../utils/getPartnerLogoAndName';

interface Props {
  isSmartphone?: boolean;
}

const MobileDetails: React.FC<Props> = (props) => {
  const { voucher, partnerInfo } = useContext(VoucherContext);
  const [termsExpanded, setTermsExpanded] = useState<boolean>(true);

  const overlayRef = useRef<HTMLDivElement>(null);
  const { onScroll, isCurrentlyAtBottom } = useDetectBottomScroll(overlayRef);

  if (!voucher || !partnerInfo) return null;

  const helpText = voucher.help_text?.value[0]?.help_text?.value
    .replace('<p>', '')
    .replace('</p>', '');

  const onlineText = voucher.online_description.value;
  const getOnlineUrl = (url: string) => {
    return url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `http://${url}`;
  };

  const voucherType = voucher.voucher_type.value[0]?.codename;
  const contentType = voucher.content_type.value[0].name;
  const voucherTypeConfig = getVoucherTypeConfig(voucherType);

  const [partnerLogo, partnerName] = getPartnerLogoAndName(
    voucher,
    partnerInfo
  );

  const getBarcodeSection = () => {
    if (voucher.barcode_image.value.length > 0)
      return (
        <div className="mobile-details__barcode-wrapper">
          <div className="mobile-details__barcode">
            <img
              src={voucher.barcode_image.value[0].url}
              alt=""
              className="mobile-details__barcode-img"
            />
          </div>
        </div>
      );

    if (!!voucher.barcode_number.value)
      return (
        <div className="mobile-details__barcode-wrapper">
          <div className="mobile-details__barcode-text">
            <IconBarcode />
            <p>{voucher.barcode_number.value}</p>
          </div>
        </div>
      );

    return null;
  };

  const getInfoIcon = (color?: string) => {
    switch (color) {
      case "blue":
        return <InfoIconBlue className="mobile-details__info-icon" />
      case "green":
        return <InfoIconGreen className="mobile-details__info-icon" />
      case "purple":
        return <InfoIconPurple className="mobile-details__info-icon" />
      default:
        return <InfoIconBlue className="mobile-details__info-icon" />
    } 
  }

  return (
    <div
      className={
        props.isSmartphone
          ? 'mobile-details__container-smartphone'
          : 'mobile-details__container'
      }
      ref={overlayRef}
      onScroll={onScroll}
    >
      <div
        className={
          props.isSmartphone ? 'mobile-details__scrollable-content' : ''
        }
      >
        <div className="mobile-details__status-bar">
          <img src={oesImage} alt="oes_background" />
          <StatusBarSvg />
        </div>
        <MobileDetailsHeader
          voucherImage={
            voucher.image_double.value.length > 0
              ? voucher.image_double.value[0].url
              : NoImgPlaceholder
          }
        />
        <div className="mobile-details__content">
          <div className="mobile-details__top-content">
            <VoucherItem
              voucher={voucher}
              partnerInfo={partnerInfo}
              type={Constants.MOBILE_DETAILS}
            />

            {contentType !== 'Online' && (
              getBarcodeSection()
            )}
            
            {helpText && (
              <div
                className="mobile-details__help-text-container"
                style={{
                  backgroundImage: voucherTypeConfig?.helpTextBackgroundColor,
                }}
              >
                <p
                  className="mobile-details__help-text"
                  style={{
                    color: voucherTypeConfig?.textColor,
                  }}
                >
                  {helpText}
                </p>
                {getInfoIcon(voucherTypeConfig?.color)}
              </div>
            )}
            {contentType !== 'POS' && (
              <div
                className="mobile-details__help-text-container"
                style={{
                  background: '#FAF6ED',
                }}
              >
                <p
                  className="mobile-details__help-text"
                  style={{
                    color: '#454555',
                  }}
                >
                  {onlineText}
                  <a
                    rel="noopener noreferrer"
                    href={getOnlineUrl(voucher.online_shop_url.value)}
                    target={'_blank'}
                    className="mobile-details__help-text-link"
                  >
                    {' '}
                    {voucher.online_shop_name.value} ›
                  </a>
                </p>
                <WebshopIcon />
              </div>
            )}
          </div>
          <div className="mobile-details__balance-info">
            <div className="mobile-details__balance-top">
              <div className="mobile-details__terms-description">
                Mein Ö Guthaben
              </div>
              <div className="mobile-details__oes">461 Ös ›</div>
            </div>
            <div className="mobile-details__balance-bottom">
              <CollectingRule
                smallLogo={partnerLogo}
                headlineText={partnerName}
                rule={partnerInfo.collecting_rule.value}
              />
            </div>
          </div>
          <div className="mobile-details__bottom-content">
            <div className="mobile-details__description">
              <p className="mobile-details__number-of-redeems">1X EINLÖSBAR</p>
              <p className="mobile-details__headline-text">
                {voucher.headline_text.value}
              </p>
              <p className="mobile-details__description-text">
                {voucher.description_text.value}
              </p>
            </div>
            <div className="mobile-details__terms-container">
              <div
                className="mobile-details__terms-title-wrapper"
                onClick={() => setTermsExpanded((prev) => !prev)}
              >
                <div className="mobile-details__terms-title-left">
                  <ImpressumIcon />
                  <p className="mobile-details__terms-title">
                    {voucher.fineprint_headline.value}
                  </p>
                </div>
                <div className="mobile-details__terms-title-right">
                  <SmallArrowUpIcon
                    className={
                      !termsExpanded ? 'mobile-details__arrow-icon' : ''
                    }
                  />
                </div>
              </div>
              <div
                className="mobile-details__terms-description"
                style={{ display: !termsExpanded ? 'none' : '' }}
              >
                {voucher.fineprint_text.value}
              </div>
            </div>
          </div>
          {props.isSmartphone ? (
            <div
              className="mobile-details__smartphone-footer"
              style={{
                background: !isCurrentlyAtBottom
                  ? 'linear-gradient(to bottom, rgba(246, 246, 249, 0), #f6f6f9)'
                  : '',
              }}
            >
              <MobileDetailsFooter />
            </div>
          ) : (
            <MobileDetailsFooter />
          )}
          <div className="mobile-details__bottom-overlay"></div>
        </div>
      </div>
    </div>
  );
};

export default MobileDetails;
