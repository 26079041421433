import React from 'react';
import './CollectingRule.css';
import { ReactComponent as LogoIcon } from '../../../../svgs/icn-filled-o-s-coins-green.svg';

interface Props {
  smallLogo: string;
  headlineText: string;
  rule: string;
}

const CollectingRule: React.FC<Props> = (props) => {
  return (
    <div className="collecting-rule__container">
        <div className="collecting-rule__left">
          <div className="collecting-rule__partner-logo-container">
            <img
              className="collecting-rule__partner-logo"
              src={props.smallLogo}
              alt=""
            />
          </div>
          <p className="collecting-rule__partner-name">{props.headlineText}</p>
        </div>
        <div className="collecting-rule__right">
          <p className="collecting-rule__text">{props.rule}</p>
          <LogoIcon className="collecting-rule__logo-earn-green"/>
        </div>
    </div>
  );
};

export default CollectingRule;
