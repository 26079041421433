import React from 'react';
import './MobileDetailsHeader.css';

interface Props {
  voucherImage: string;
}

const MobileDetailsHeader: React.FC<Props> = (props) => {
  return (
    <div className="mobile-details__header-container">
      <div className="mobile-details__header-top">
        <div className="voucher-item__img-container__mobile">
          <img
            className="voucher-item__img-square"
            src={props.voucherImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MobileDetailsHeader;
