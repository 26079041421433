import React from 'react';
import './VoucherItem.css';
import VoucherItemType from './VoucherItemType/VoucherItemType';
import { ReactComponent as HeartIcon } from '../../../svgs/icn-heart-outline.svg';
import { ReactComponent as SlidesIcon } from '../../../svgs/slides.svg';
import { ReactComponent as ClockIcon } from '../../../svgs/icn-clock_grey.svg';
import { ReactComponent as RedClockIcon } from '../../../svgs/icn-clock_red.svg';
import { ReactComponent as AppExclusive } from '../../../svgs/app-exlusive.svg';
import VoucherItemHeader from './VoucherItemHeader/VoucherItemHeader';
import NoImgPlaceholder from '../../../images/no-img-placeholder-2.png';
import NoImgBarcode from '../../../images/no-img-barcode.png';
import Constants from '../../../resources/constants/constants';
import VoucherModel from '../../../models/voucher/VoucherModel';
import PartnerInfoModel from '../../../models/voucher/PartnerInfoModel';
import voucherTimeLeft from '../../../utils/voucherTimeLeft';
import getPartnerLogoAndName from '../../../utils/getPartnerLogoAndName';

interface Props {
  type: string;
  voucher: VoucherModel;
  partnerInfo: PartnerInfoModel;
}

const VoucherItem: React.FC<Props> = (props) => {
  const { type, voucher, partnerInfo } = props;
  const [voucherDuration, showFireIcon] = voucherTimeLeft(
    voucher.end_date.value
  );

  const isAppOnly = voucher.app_only.value.length;

  const [partnerLogo, partnerName] = getPartnerLogoAndName(
    voucher,
    partnerInfo
  );

  if (
    type === Constants.BARCODE &&
    voucher.content_type.value[0].name === Constants.ONLINE
  )
    return null;

  const classSwitch = () => {
    switch (type) {
      case Constants.BARCODE:
        return 'voucher-item__img-container__barcode';
      case Constants.SLIDER:
        return 'voucher-item__img-container__slider';
      case Constants.MOBILE_DETAILS:
        return 'voucher-item__img-container__mobile';
      default:
        return 'voucher-item__img-container';
    }
  };

  const switchClassContainer = () => {
    switch (type) {
      case Constants.BARCODE:
        return 'container-slider';
      case Constants.MOBILE_DETAILS:
        return 'container-slider-mobile';
      case Constants.SLIDER:
        return 'container-slider';
      default:
        return 'container-list';
    }
  };

  const displayCorrectImage = () => {
    if (type === Constants.BARCODE)
      return voucher.barcode_image.value.length > 0
        ? voucher.barcode_image.value[0].url
        : NoImgPlaceholder;
    else
      return voucher.image_double.value.length > 0
        ? voucher.image_double.value[0].url
        : NoImgPlaceholder;
  };

  return (
    <div className={switchClassContainer()}>
      <div
        className={
          type === Constants.MOBILE_DETAILS
            ? 'voucher-item__header-container-mobile'
            : 'voucher-item__header-container'
        }
      >
        {type === Constants.MOBILE_DETAILS && (
          <SlidesIcon className="voucher-item__slides-icn" />
        )}
      </div>
      {type !== Constants.BARCODE && type !== Constants.MOBILE_DETAILS && (
        <VoucherItemHeader
          smallLogo={partnerLogo}
          headlineText={partnerName}
          voucherContentType={voucher.content_type.value[0]?.name}
          isPermanentPromotion={voucher.permanent_promotion.value.length === 0}
          endDate={voucher.end_date.value}
        />
      )}
      <div className={'voucher-item__content'}>
        {type === Constants.BARCODE &&
        voucher.barcode_image.value.length === 0 ? (
          <div className="voucher-item__container__barcode-no-img">
            <img
              className="voucher-item__barcode-placeholder"
              src={NoImgBarcode}
              alt=""
            />
            <p className="voucher-item__barcode-number">
              {voucher.barcode_number.value}
            </p>
          </div>
        ) : (
          <>
            {type !== Constants.MOBILE_DETAILS && (
              <div className={classSwitch()}>
                <img
                  className={
                    type === Constants.VORTEILSBON
                      ? 'voucher-item__img-square'
                      : 'voucher-item__img'
                  }
                  src={displayCorrectImage()}
                  alt=""
                />
              </div>
            )}
            {type !== Constants.BARCODE && (
              <>
                <div
                  className={
                    type === Constants.MOBILE_DETAILS
                      ? 'voucher-item__details-mobile'
                      : 'voucher-item__details'
                  }
                >
                  <p className="voucher-item__description">
                    {voucher.name.value}
                  </p>
                </div>
                <div>
                  <div
                    className={
                      type === Constants.MOBILE_DETAILS
                        ? 'voucher-item__details-bottom-mobile'
                        : 'voucher-item__details-bottom'
                    }
                    style={{ paddingBottom: isAppOnly ? '10px' : '16px' }}
                  >
                    <VoucherItemType
                      voucherType={voucher.voucher_type.value[0]?.codename}
                      coinsForType={voucher.coins_for_type.value}
                    />
                    {type === Constants.MOBILE_DETAILS ? (
                      <div>
                        {voucher.permanent_promotion.value.length === 0 ? (
                          <div className="voucher-item__duration-container">
                            <div className="voucher-item__duration">
                              <p className="voucher-item__duration-text">
                                {voucherDuration}
                              </p>
                            </div>
                            {showFireIcon ? <RedClockIcon /> : <ClockIcon />}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="voucher-item__heart-icn">
                        <HeartIcon />
                      </div>
                    )}
                  </div>
                  {isAppOnly ? (
                    <div
                      className={
                        type === Constants.MOBILE_DETAILS
                          ? 'voucher-item__exclusive'
                          : 'voucher-item__exclusive-mobile'
                      }
                    >
                      <AppExclusive />
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VoucherItem;
