import React from 'react';
import './MobileDetailsFooter.css';
import { ReactComponent as IconClose } from '../../../../svgs/icn-close-medium.svg';
import { ReactComponent as IconHeart } from '../../../../svgs/icn-heart-outline.svg';
import { ReactComponent as IconCard } from '../../../../svgs/icn-card.svg';

const MobileDetailsFooter = () => {
  return (
    <div className="footer">
      <div className="footer_wrapper">
        <div className="footer_button footer_round footer_close">
          <IconClose />
        </div>
        <div className="footer_button footer_meine-jo-karte">
          <IconCard />
          <p className="footer_text">Meine jö Karte</p>
        </div>
        <div className="footer_button footer_round footer_heart">
          <IconHeart />
        </div>
      </div>
      <div className="footer_grey-line"></div>
    </div>
  );
};

export default MobileDetailsFooter;
