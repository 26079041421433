import voucherTypes from '../resources/constants/VoucherTypes';

interface VoucherTypeInterface {
  type: string;
  codename: string;
  backgroundColor: string;
  textColor: string;
  text: string;
  color: string;
  helpTextBackgroundColor: string;
}

const getVoucherTypeConfig = (voucherType: string) => {
  const voucherTypeConfig: VoucherTypeInterface | undefined = Object.values(
    voucherTypes
  ).find((item) => item.codename === voucherType);

  return voucherTypeConfig;
};

export default getVoucherTypeConfig;
