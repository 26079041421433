import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import VoucherProvider from './components/Voucher/VoucherProvider';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/:projectId/voucher-preview/:voucherSlug"
          render={(props) => <VoucherProvider {...props} />}
        />
        <Route exact path="/health">
          <h1>OK</h1>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
