import moment from 'moment-timezone';

export interface TimeLeft {
  days: number;
  hours: number;
}

export default (finishDate: string, startDate?: string) => {
  const timeLeft: TimeLeft = { days: 0, hours: 0 };
  const finishDateObj = moment(
    new Date(moment.tz(finishDate, 'DD.MM.YYYY', 'Europe/Vienna').toDate())
  ).add(1, 'day');
  const now = startDate
    ? moment(new Date(startDate))
    : moment(new Date(moment.tz('Europe/Vienna').toDate()));
  const dateDiff = moment.duration(finishDateObj.diff(now));
  let count = dateDiff.asDays();
  if (count >= 1) {
    timeLeft.days = Math.floor(count);
  } else {
    count = Math.floor(dateDiff.asHours());
    timeLeft.hours = count;
  }

  return [getExpirationText(timeLeft), timeLeft.hours > 0];
};

const getExpirationText = (timeLeft: TimeLeft) => {
  if (timeLeft.days > 0) return `Noch ${timeLeft.days} Tage`;

  if (timeLeft.hours < 1) return 'Abgelaufen';

  return `noch ${timeLeft.hours} Stunden`;
};
