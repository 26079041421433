import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class VoucherModel extends ContentItem {
  public name: Elements.TextElement;
  public ocard_promotion_id: Elements.TextElement;
  public partner_id: Elements.TextElement;
  public partner_promotion_id: Elements.TextElement;
  public start_date: Elements.TextElement;
  public end_date: Elements.TextElement;
  public image_double: Elements.AssetsElement;
  public small_logo: Elements.AssetsElement;
  public barcode_image: Elements.AssetsElement;
  public content_type: Elements.MultipleChoiceElement;
  public coins_for_type: Elements.TextElement;
  public voucher_type: Elements.MultipleChoiceElement;
  public permanent_promotion: Elements.MultipleChoiceElement;
  public help_text: Elements.LinkedItemsElement;
  public headline_text: Elements.TextElement;
  public description_text: Elements.TextElement;
  public online_description: Elements.TextElement;
  public fineprint_headline: Elements.TextElement;
  public fineprint_text: Elements.TextElement;
  public online_shop_name: Elements.TextElement;
  public online_shop_url: Elements.TextElement;
  public barcode_number: Elements.TextElement;
  public app_only: Elements.MultipleChoiceElement;
  public show_as: Elements.MultipleChoiceElement;
}
