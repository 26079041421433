import React from 'react';
import { ReactComponent as CircleCardIcon } from '../../../../svgs/icn_circle_arrow.svg';
import './VoucherPreviewItemTitle.css';

interface Props {
    text: string
}

const VoucherPreviewItemTitle: React.FC<Props> = (props) => {
  return (
    <div className="voucher-preview-item-title__container">
      <CircleCardIcon />
      <p className="voucher-preview-item-title__text">{props.text}</p>
    </div>
  );
};

export default VoucherPreviewItemTitle;
