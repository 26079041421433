export default {
    burn: {
        type: 'Burn',
        codename: 'burn',
        backgroundColor: 'rgba(74, 139, 192, 0.1)',
        textColor: '#454555',
        helpTextBackgroundColor: 'linear-gradient(to right, rgba(74, 139, 192, 0.1) 1%, rgba(74, 139, 192, 0.07)',
        text: ' Ös einlösen',
        color: 'blue'
    },
    earn: {
        type: 'Earn',
        codename: 'earn',
        backgroundColor: 'rgba(140, 180, 44, 0.1)',
        textColor: '#454555',
        helpTextBackgroundColor: 'linear-gradient(to right, rgba(140, 180, 44, 0.1) 1%, rgba(140, 180, 44, 0.07)',
        text: ' Ös sammeln',
        color: 'green'
    },
    earnMultiple: {
        type: 'Earn Multiple',
        codename: 'earn_multiple',
        backgroundColor: 'rgba(140, 180, 44, 0.1)',
        textColor: '#454555',
        helpTextBackgroundColor: 'linear-gradient(to right, rgba(140, 180, 44, 0.1) 1%, rgba(140, 180, 44, 0.07)',
        text: 'fach Ös sammeln',
        color: 'green'
    },
    earnExtra: {
        type: 'Earn Extra',
        codename: 'earn_extra',
        backgroundColor: 'rgba(140, 180, 44, 0.1)',
        textColor: '#454555',
        helpTextBackgroundColor: 'linear-gradient(to right, rgba(140, 180, 44, 0.1) 1%, rgba(140, 180, 44, 0.07)',
        text: ' Zusatz Ös sammeln',
        color: 'green'
    },
    showYourCard: {
        type: 'Show Your Card',
        codename: 'show_your_card',
        backgroundColor: 'rgba(108, 128, 219, 0.1)',
        textColor: '#454555',
        helpTextBackgroundColor: 'linear-gradient(to right, rgba(108, 128, 219, 0.1) 1%, rgba(108, 128, 219, 0.07)',
        text: 'mit jö Karte',
        color: 'purple'
    },
    noTag: {
        type: 'No Tag',
        codename: 'no_tag',
        backgroundColor: '',
        textColor: '#454555',
        helpTextBackgroundColor: 'linear-gradient(to right, rgba(108, 128, 219, 0.1) 1%, rgba(108, 128, 219, 0.07)',
        text: '',
        color: 'purple'
    },
}