import React from 'react';
import { ReactComponent as LogoIcon } from '../../../svgs/assets-logo.svg';
import { ReactComponent as ErrorIcon } from '../../../svgs/icn-error-header.svg';
import './Header.css';
import VoucherHeader from '../VoucherHeader/VoucherHeader';

interface HeaderProps {
  isLoading: boolean;
  setIsLoading: any;
  isError?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  isLoading,
  setIsLoading,
  isError = false,
}) => {
  return (
    <div className="header-container">
      <div className="header__top-part">
        <LogoIcon className="header__jo-logo" />
        <div className="header__flex">
          <p className="header__title">Vorteilsbon Preview</p>
          {isError && (
            <div className="header__flex__error">
              <ErrorIcon className="header__error__icon" />
              <p className="header__error__message">
                Bitte Feld <strong>ÖCard Promotion Id</strong> und
                <strong> Partner Promotion Id</strong> auf Leerzeichen und
                Absatz prüfen.
              </p>
            </div>
          )}
        </div>
      </div>
      <VoucherHeader setIsLoading={setIsLoading} isLoading={isLoading} />
    </div>
  );
};

export default Header;
