import React from 'react';
import { ReactComponent as ClockIcon } from '../../../../svgs/icn-clock-grey.svg';
import { ReactComponent as RedClockIcon } from '../../../../svgs/icn-clock-red.svg';
import './VoucherItemHeader.css';
import voucherTimeLeft from '../../../../utils/voucherTimeLeft';

interface Props {
  smallLogo: string;
  headlineText: string;
  voucherContentType: string;
  isPermanentPromotion: boolean;
  endDate: string;
  isMobileDetails?: boolean;
}

const VoucherItemHeader: React.FC<Props> = (props) => {
  const [voucherDuration, showFireIcon] = voucherTimeLeft(props.endDate);

  return (
    <div className="voucher-item__header-container">
      <div
        className={
          props.isMobileDetails
            ? 'voucher-item__header-mobile'
            : 'voucher-item__header'
        }
      >
        <div className="voucher-item__header-left">
          <div className="voucher-item__partner-logo-container">
            <img
              className="voucher-item__partner-logo"
              src={props.smallLogo}
              alt=""
            />
          </div>
          <p className="voucher-item__name">{props.headlineText}</p>
        </div>
        <div className="voucher-item__header-right">
          {props.isPermanentPromotion ? (
            <div className="voucher-item__duration-container">
              <div className="voucher-item__duration">
                <p className="voucher-item__duration-text">{voucherDuration}</p>
              </div>
              {showFireIcon ? (
                <div className="voucher-item__duration-icon">
                  <RedClockIcon />
                </div>
              ) : (
                <div className="voucher-item__duration-icon">
                  <ClockIcon />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VoucherItemHeader;
