import React from 'react';
import { ReactComponent as LogoIcon } from '../../../../svgs/icn-filled-o-s-coins.svg';
import './VoucherItemType.css';
import getVoucherTypeConfig from '../../../../utils/getVoucherTypeConfig';

interface Props {
  voucherType: string;
  coinsForType: string;
}

const VoucherItemType: React.FC<Props> = (props) => {
  const { coinsForType, voucherType } = props;

  const voucherTypeConfig = getVoucherTypeConfig(voucherType);

  return (
    <div
      className="voucher-item-type__container"
      style={{ backgroundColor: voucherTypeConfig?.codename !== 'no_tag' ? '#f6f6f9' : 'transparent' }}
    >
      {voucherTypeConfig?.codename !== 'no_tag' && (
        <>
          <LogoIcon className={`voucher-item-type__logo-${voucherTypeConfig?.color}`} />
          <p className="voucher-item-type__text" style={{ color: voucherTypeConfig?.textColor }}>
            {voucherTypeConfig?.codename !== 'show_your_card'
              ? `${coinsForType}${voucherTypeConfig?.text}`
              : voucherTypeConfig.text}
          </p>
        </>
      )}
    </div>
  );
};

export default VoucherItemType;
