import React, { useState } from 'react';

const useDetectBottomScroll = (ref: React.RefObject<HTMLDivElement>) => {
  const [isCurrentlyAtBottom, setIsCurrentlyAtBottom] = useState<boolean>(
    false
  );
  const onScroll = () => {
    if (ref && ref.current) {
      const { clientHeight, scrollHeight, scrollTop } = ref.current;
      if (scrollHeight - scrollTop - 50 <= clientHeight) {
        setIsCurrentlyAtBottom(true);
      } else {
        setIsCurrentlyAtBottom(false);
      }
    }
  };

  return { onScroll, isCurrentlyAtBottom };
};

export default useDetectBottomScroll;
