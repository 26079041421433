import { TypeResolver } from '@kentico/kontent-delivery'
import VoucherModel from '../models/voucher/VoucherModel'
import PartnerInfoModel from '../models/voucher/PartnerInfoModel'

export default {
  voucherTypeResolvers: [
    new TypeResolver('voucher', () => new VoucherModel()),
    new TypeResolver('partner_info', () => new PartnerInfoModel()),
  ],
}

