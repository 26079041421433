import React, { useContext } from 'react';
import { VoucherContext } from '../VoucherContext';
import './VoucherHeader.css';
import axios from 'axios';
import { saveAs } from 'file-saver';

interface VoucherHeaderProps {
  setIsLoading: any;
  isLoading: boolean;
}

const VoucherHeader: React.FC<VoucherHeaderProps> = ({
  setIsLoading,
  isLoading,
}) => {
  const { voucher, partnerInfo } = useContext(VoucherContext);

  if (!voucher || !partnerInfo) return null;

  const OPID = voucher.ocard_promotion_id.value;
  const PPID = voucher.partner_promotion_id.value;
  const headlineText = partnerInfo.headline_text.value;

  const url = window.location.href;
  const downloadPDf = () => {
    setIsLoading(true);
    axios
      .get(`/api/export-pdf?url=${url}`, { responseType: 'blob' })
      .then((res) => {
        setIsLoading(false);
        const blob = new Blob([res.data], { type: 'application/pdf' });
        saveAs(blob, `ÖPID:${OPID}_PPID:${PPID}_${headlineText}.pdf`);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
    <div className="voucher-header__container">
      <div className="voucher-header__logo-container">
        <img
          src={
            voucher.partner_id.value.toLowerCase() === 'billa'
              ? partnerInfo.logo_1.value[0].url
              : partnerInfo.small_logo.value[0].url
          }
          alt=""
          className="voucher__logo"
        />
      </div>
      <div className="voucher-header__info-container">
        <div className="voucher-header__info-left">
          <p className="voucher-header__name">{voucher.name.value}</p>
          <p className="voucher-header__duration">
            Gültigkeit: {voucher.start_date.value} - {voucher.end_date.value}
          </p>
        </div>
        <div className="voucher_header__info-right">
          <div className="voucher-header__id-container">
            <p className="voucher-header__opid voucher-header__ppid">
              {`ÖPID: ${OPID}, PPID: ${PPID}`}
            </p>
          </div>
          <div className="voucher-header__pdf">
            <button
              className="voucher-header__pdf-btn btn btn-primary btn-rounded"
              onClick={downloadPDf}
              disabled={isLoading}
            >
              <span className="voucher-header__pdf-btn-label">
                PDF herunterladen
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherHeader;
