export default {
  billa: {
    projectId: '24686fb7-4a5c-01ca-7f96-2e7f1ae5e501',
    codeName: 'partner___billa',
  },
  bawag: {
    projectId: '49b7ee70-1c51-0151-eaf1-601cef9ae4c5',
    codeName: 'partner___bawag_p_s_k',
  },
  bipa: {
    projectId: 'eb773334-2d5d-012e-1939-2eb3bac82d76',
    codeName: 'partner___bipa',
  },
  adeg: {
    projectId: '5e324bd4-1618-01c6-97fe-f86ada13e4d8',
    codeName: 'partner___adeg',
  },
  interio: {
    projectId: '9ec384fd-cf96-01a0-6ac5-7a8742fab95c',
    codeName: 'partner___interio',
  },
  libro: {
    projectId: '174c625b-f1d4-0131-2b01-5b137a951dd2',
    codeName: 'partner___libro',
  },
  mjam: {
    projectId: '07ff4b72-2a57-017f-236b-9e06bece7951',
    codeName: 'partner___mjam',
  },
  omv: {
    projectId: 'dbdbed33-5f73-01f8-9e66-276c3bf252b7',
    codeName: 'partner___omv',
  },
  pagro: {
    projectId: '6285c19a-540f-01f2-13c4-a9a34b653b85',
    codeName: 'partner___pagro_diskont',
  },
  pearle: {
    projectId: 'b872b3ae-8daf-014e-3be3-2a487fbea52d',
    codeName: 'partner___pearle',
  },
  penny: {
    projectId: '9b280ae7-69bb-01de-32b0-ade436f755be',
    codeName: 'partner___penny',
  },
  universal: {
    projectId: '541237a2-18b8-0126-4aa9-2f64698a642e',
    codeName: 'partner___universal',
  },
  verbund: {
    projectId: 'cbea13d1-2001-01d3-a485-d78203a53d35',
    codeName: 'partner___verbund',
  },
  zgonc: {
    projectId: 'c79670f8-c7e5-0150-437c-7aabc0fa89d1',
    codeName: 'partner___zgonc',
  },
  allianz: {
    projectId: '956e099e-d211-01ec-7253-fcec72a0ddf0',
    codeName: 'partner___allianz',
  },
  sportPass: {
    projectId: '440f32e6-1c89-010b-b019-384900530d36',
    codeName: 'partner___sportpass',
  },
  joBonusClub: {
    projectId: '840e50e1-4b3c-0126-a865-16578eea53e7',
    codeName: 'partner___jo_bonus_club',
  },
};
