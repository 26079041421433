import projectsTypeResolvers from "./partnerTypeResolver";

export default {
    projectId: '75bc6d94-a2f5-017f-0750-b83993aed5a4',
    previewApiKey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNTZlOTgxOWQyM2M0ODA3OWFkMmZlNGU2ODhjMzUxOSIsImlhdCI6MTU1MTk2MDU1OSwibmJmIjoxNTUxOTYwNTU5LCJleHAiOjE4OTc1NjA1NTksInZlciI6IjEuMC4wIiwicHJvamVjdF9pZCI6Ijc1YmM2ZDk0YTJmNTAxN2YwNzUwYjgzOTkzYWVkNWE0IiwiYXVkIjoicHJldmlldy5kZWxpdmVyLmtlbnRpY29jbG91ZC5jb20ifQ.IhODrXro_GyoLWc7gbRFY0YVWbtEAk32xH6c-aZgXSI',
    globalQueryConfig: {
        usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.partnerTypeResolver,
}