import {TypeResolver} from "@kentico/kontent-delivery";
import PartnerInfoModel from "../models/voucher/PartnerInfoModel";

export default {
    partnerTypeResolver: [
        new TypeResolver('partner___billa', () => new PartnerInfoModel()),
        new TypeResolver('partner___bawag_p_s_k', () => new PartnerInfoModel()),
        new TypeResolver('partner___bipa', () => new PartnerInfoModel()),
        new TypeResolver('partner___adeg', () => new PartnerInfoModel()),
        new TypeResolver('partner___interio', () => new PartnerInfoModel()),
        new TypeResolver('partner___libro', () => new PartnerInfoModel()),
        new TypeResolver('partner___mjam', () => new PartnerInfoModel()),
        new TypeResolver('partner___omv', () => new PartnerInfoModel()),
        new TypeResolver('partner___pagro_diskont', () => new PartnerInfoModel()),
        new TypeResolver('partner___pearle', () => new PartnerInfoModel()),
        new TypeResolver('partner___penny', () => new PartnerInfoModel()),
        new TypeResolver('partner___universal', () => new PartnerInfoModel()),
        new TypeResolver('partner___verbund', () => new PartnerInfoModel()),
        new TypeResolver('partner___zgonc', () => new PartnerInfoModel()),
        new TypeResolver('partner___allianz', () => new PartnerInfoModel()),
        new TypeResolver('partner___sportpass', () => new PartnerInfoModel()),
    ],
}