import { ContentItem, Elements } from '@kentico/kontent-delivery';

export default class PartnerInfoModel extends ContentItem {
  public partner_id: Elements.TextElement;
  public project_id: Elements.TextElement;
  public organization_api_key: Elements.TextElement;
  public organization_api_key___production: Elements.TextElement;
  public display_name: Elements.TextElement;
  public partner_name_1: Elements.TextElement;
  public partner_name_2: Elements.TextElement;
  public partner_name_3: Elements.TextElement;
  public partner_name_loyalty: Elements.TextElement;
  public partner_name_tx_history: Elements.TextElement;
  public full_name: Elements.TextElement;
  public tags: Elements.MultipleChoiceElement;
  public discount_redeemed_title: Elements.TextElement;
  public discount_redeemed_description: Elements.TextElement;
  public publitas_id: Elements.TextElement;
  public survey: Elements.TextElement;
  public primary_color: Elements.TextElement;
  public contrast_color: Elements.TextElement;
  public small_logo: Elements.AssetsElement;
  public large_logo: Elements.AssetsElement;
  public logo_1: Elements.AssetsElement;
  public logo_2: Elements.AssetsElement;
  public logo_3: Elements.AssetsElement;
  public logo_loyalty: Elements.AssetsElement;
  public logo_tx_history: Elements.AssetsElement;
  public disclaimer_text: Elements.TextElement;
  public teaser_image: Elements.AssetsElement;
  public collecting_rule: Elements.TextElement;
  public content_page_id: Elements.TextElement;
  public content_page_id_2: Elements.TextElement;
  public content_page_name_2: Elements.TextElement;
  public help_text_loyalty: Elements.TextElement;
}
