import React from 'react'
import VoucherModel from '../../models/voucher/VoucherModel'
import PartnerInfoModel from '../../models/voucher/PartnerInfoModel'

interface ContextProps {
  voucher: VoucherModel,
  partnerInfo: PartnerInfoModel
}

export const VoucherContext = React.createContext<Partial<ContextProps>>({})
