import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header/Header';
import './VoucherPreview.css';
import VoucherItem from '../VoucherItem/VoucherItem';
import { VoucherContext } from '../VoucherContext';
import MobileDetails from '../MobileDetails/MobileDetails';
import VoucherPreviewItemTitle from './VoucherPreviewItemTitle/VoucherPreviewItemTitle';
import IphoneOverlayImg from '../../../images/iphone-gold.png';
import Constants from '../../../resources/constants/constants';
import Loader from 'react-loader-spinner';
import checkForErrorsInID from '../../../utils/checkForErrorsInID';

const VoucherPreview: React.FC = () => {
  const voucherCtx = useContext(VoucherContext);

  const { voucher, partnerInfo } = voucherCtx;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    setIsError(
      checkForErrorsInID(voucher?.ocard_promotion_id.value) ||
        checkForErrorsInID(voucher?.partner_promotion_id.value)
    );
  }, [voucher?.ocard_promotion_id.value, voucher?.partner_promotion_id.value]);

  if (!voucher || !partnerInfo) return null;

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="voucher-header__loader">
          <Loader type="ThreeDots" color="#d6ab53" width={100} height={100} />
        </div>
      ) : null}
      <div className="voucher-preview__header">
        <Header
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          isError={isError}
        />
      </div>
      <div className="voucher-content">
        <div className="voucher__mobile-details-view">
          <div className="voucher__mobile__smartphone-container">
            <VoucherPreviewItemTitle
              text={'Ansicht App Vorteilsbon Smartphone '}
            />
            <div className="voucher__mobile__smartphone-overlay">
              <img
                className="voucher__mobile__smartphone-overlay-img "
                src={IphoneOverlayImg}
                alt=""
              />
              <MobileDetails isSmartphone />
            </div>
          </div>
          <div className="voucher__mobile-list-view">
            <div className="voucher-content__list-container">
              <VoucherPreviewItemTitle text={'Ansicht App Vorteilsbon'} />
              <VoucherItem
                voucher={voucher}
                partnerInfo={partnerInfo}
                type={Constants.VORTEILSBON}
              />
            </div>
          </div>
        </div>
        <div className="voucher__mobile__details-container">
          <VoucherPreviewItemTitle
            text={'Ansicht Vorteilsbon mit allen Details '}
          />
          <MobileDetails />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VoucherPreview;
