import PartnerInfoModel from '../models/voucher/PartnerInfoModel';
import VoucherModel from '../models/voucher/VoucherModel';

const getPartnerLogoAndName = (
  voucher: VoucherModel,
  partnerInfo: PartnerInfoModel
) => {
  if (
    voucher.partner_id.value.toLowerCase() !== 'billa' ||
    voucher.show_as.value.length === 0
  )
    return [
      partnerInfo.small_logo.value[0]?.url,
      partnerInfo.display_name.value,
    ];

  switch (voucher.show_as.value[0].codename) {
    case 'billa_billa_plus':
      return [partnerInfo.logo_1.value[0].url, 'BILLA + BILLA PLUS'];
    case 'billa':
      return [partnerInfo.logo_2.value[0].url, 'BILLA'];
    case 'billa_plus':
      return [partnerInfo.logo_3.value[0].url, 'BILLA PLUS'];
    default:
      return [partnerInfo.logo_1.value[0].url, 'BILLA + BILLA PLUS'];
  }
};

export default getPartnerLogoAndName;
